import { useRouter } from "next/navigation";
import { useState, type Dispatch, type SetStateAction } from "react";
import Button from "./base/Button";
import { api } from "~/server/trpc/react";
import { Skeleton } from "./lists/MainPage/SharedTable/utils";
import { Dialog } from "@headlessui/react";
import { useLists } from "~/providers/ListsProvider";

export default function AllEvalsSummaryModal({
  setShowModal,
  showModal,
  userId,
  teamAccountId,
}: Readonly<{
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  userId: string | undefined;
  teamAccountId: string;
}>) {
  const [isRunningEval, setIsRunningEval] = useState(false);
  const { handleRefetch } = useLists();
  const isLoadingCounts = false;

  const router = useRouter();

  const { data: repoContactsCount = 0 } =
    api.listContact.overviewContactCount.useQuery({
      teamAccountId: teamAccountId,
      type: "repo",
    });
  const { data: userContactsCount = 0 } =
    api.listContact.overviewContactCount.useQuery({
      teamAccountId: teamAccountId,
      type: "user",
    });

  const { mutate: reEvaluateAllLists } =
    api.evaluation.reEvaluateAllLists.useMutation({
      onSuccess: () => {
        handleRefetch();
      },
    });

  const runEvaluations = () => {
    if (!userId || !teamAccountId) return;
    setIsRunningEval(true);
    reEvaluateAllLists({
      teamAccountId: teamAccountId,
    });
    setIsRunningEval(false);
    setShowModal(false);

    router.push("/?overview=true");
  };

  return (
    <Dialog
      open={showModal}
      onClose={() => setShowModal(false)}
      className="relative z-50"
    >
      <div className="fixed inset-0 bg-black bg-opacity-50 flex w-screen items-center justify-center p-4">
        <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white px-16 py-4 text-left align-middle shadow-xl transition-all">
          <Dialog.Title className=" flex flex-col content-center items-center justify-center gap-2 text-lg leading-6 ">
            <span className="py-2 text-2xl">Confirm Evaluations</span>
          </Dialog.Title>
          <Dialog.Description>
            <div className="whitespace-normal px-12 pt-2">
              You are about to trigger ALL evaluations related to this team
              account. Please review the data and confirm:
            </div>
          </Dialog.Description>
          <div>
            {" "}
            <div className="whitespace-normal px-12">
              <ul className="flex list-disc flex-col space-y-3 p-8 pl-4">
                <li>
                  {isLoadingCounts ? (
                    <Skeleton width="w-32" />
                  ) : (
                    <>
                      {repoContactsCount} Repositories and {userContactsCount}{" "}
                      Users
                    </>
                  )}
                </li>{" "}
              </ul>
              <div>
                Please note that any further evaluations will be queued.
              </div>
            </div>
          </div>

          <div className="flex justify-end py-4">
            <Button disabled={isRunningEval} onClick={runEvaluations}>
              Confirm
            </Button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
