import type { Icon } from "@primer/octicons-react";
import { useRef } from "react";
import useOnClickOutside from "~/hooks/useOnClickOutside";
export interface DropDownItem {
  name: string;
  icon?: Icon;
  id?: string;
}

export default function DropDown({
  items,
  filterOpen,
  selectItem,
  closeDropDown,
  className = "",
  listClassName = "",
  reposToBoost = 0,
}: Readonly<{
  items: DropDownItem[];
  filterOpen: boolean;
  selectItem: (name: string, id?: string) => void;
  closeDropDown: () => void;
  className?: string;
  listClassName?: string;
  reposToBoost?: number;
}>) {
  const refVal = useRef(null);
  useOnClickOutside(refVal, closeDropDown);
  if (!items.length) return <></>;
  return (
    <ul
      ref={refVal}
      className={`${
        filterOpen ? "" : "hidden"
      } absolute  z-50 flex w-36 flex-col gap-2 rounded-md border p-2 ${className}`}
    >
      {items.map((item, index) => {
        const optionalDanger = item.name === "Delete" ? "text-red-500" : "";
        const optionalBoost =
          item.name === "Boost" && reposToBoost <= 0
            ? "text-gray-400 !cursor-not-allowed"
            : "";
        return (
          <li key={item.name + index}>
            <div
              className={`flex w-full ${optionalDanger} ${optionalBoost} cursor-pointer items-center justify-between ${listClassName}`}
              onClick={() => selectItem(item.name, item.id)}
            >
              {item.name}
              {item.icon && (
                <item.icon className={`sm-icon ${optionalDanger}`} />
              )}
            </div>
          </li>
        );
      })}
    </ul>
  );
}
