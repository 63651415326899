import { useState } from "react";
import { getRepoOrUser } from "~/lib/utils";
import { useTeamAccount } from "~/providers/TeamAccountProvider";
import { api } from "~/server/trpc/react";

const useCsvExport = (
  tableName: string,
  activeHeaderMenu: string,
  listId?: string
) => {
  const [isDownloadingCsv, setIsDownloading] = useState(false);

  const { mutate: getCsv } = api.list.listExport.useMutation({
    onSuccess: (data) => {
      if (data) {
        const blob = new Blob([data], { type: "text/csv" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${tableName}_${activeHeaderMenu.toLowerCase()}.csv`;
        a.click();
        URL.revokeObjectURL(url);
      }
    },
  });

  const { activeTeamAccount } = useTeamAccount();
  const activeTeamAccountId = activeTeamAccount?.id;
  const downloadCsv = async () => {
    setIsDownloading(true);
    getCsv({
      teamAccountId: activeTeamAccountId,
      listId,
      type: getRepoOrUser(activeHeaderMenu),
    });
    setIsDownloading(false);
  };

  return { downloadCsv, isDownloadingCsv };
};

const useTomlExport = (
  tableName: string,
  activeHeaderMenu: string,
  importedToml: string | null,
  listId?: string
) => {
  const [isDownloadingToml, setIsDownloadingToml] = useState(false);
  const { activeTeamAccount } = useTeamAccount();
  const activeTeamAccountId = activeTeamAccount?.id;
  const activeTeamAccountName = activeTeamAccount?.name;

  const { mutate: getToml } = api.list.listExportToml.useMutation({
    onSuccess: (data) => {
      if (data) {
        const blob = new Blob([data], { type: "application/toml" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${tableName}.toml`;
        a.click();
        URL.revokeObjectURL(url);
      }
    },
  });

  const downloadToml = async () => {
    setIsDownloadingToml(true);
    getToml({
      teamAccountId: activeTeamAccountId,
      listId,
      type: getRepoOrUser(activeHeaderMenu),
      teamName: activeTeamAccountName,
      importedToml: importedToml ?? "",
    });
    setIsDownloadingToml(false);
  };

  return { downloadToml, isDownloadingToml };
};

export { useCsvExport, useTomlExport };
