import { useEffect, useRef, useState } from "react";
import { api } from "~/server/trpc/react";
import { useLists } from "~/providers/ListsProvider";
import { useList } from "~/providers/ListProvider";
import { useTeamAccount } from "~/providers/TeamAccountProvider";
import { useRouter } from "next/navigation";
import LoadingSpinner from "~/loaders/LoadingSpinner";
import Button from "./Button";

export default function DeleteModal({
  setDeleteModal,
}: Readonly<{
  setDeleteModal: (deleteModal: boolean) => void;
}>) {
  const modal = useRef<HTMLDivElement>(null);
  const router = useRouter();
  const [deleting, setDeleting] = useState(false);
  const list = useList();
  const { activeTeamAccount } = useTeamAccount();
  const { handleRefetch } = useLists();
  const { mutate: deleteList } = api.list.deleteList.useMutation({
    onSuccess: () => {
      setDeleting(false);
      setDeleteModal(false);
      handleRefetch();
      router.push("/");
    },
  });

  useEffect(() => {
    // Courtesy of https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
    function handleClickOutside(event: MouseEvent) {
      if (modal.current && !modal?.current.contains(event.target as Node)) {
        setDeleteModal(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modal, setDeleteModal]);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg" ref={modal}>
        <p className="text-lg font-semibold mb-4">
          Are you sure you want to delete this list?
        </p>
        <div className="flex justify-end gap-4">
          <Button onClick={() => setDeleteModal(false)}>Cancel</Button>
          <Button
            className="bg-red-500 hover:bg-red-600"
            onClick={() => {
              if (list && activeTeamAccount?.id) {
                setDeleting(true);
                deleteList({
                  listId: list.id,
                  teamAccountId: activeTeamAccount.id,
                });
              }
            }}
          >
            Delete
            {deleting && (
              <LoadingSpinner show={true} className="ml-2 sm-icon" />
            )}
          </Button>
        </div>
      </div>
    </div>
  );
}
