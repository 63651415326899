import { useEffect, useRef, useState } from "react";
import LoadingSpinner from "~/loaders/LoadingSpinner";
import Button from "./Button";
import toml from "@iarna/toml";
import { useTomlExport } from "~/hooks/useExport";

export default function CompareModal({
  activeTabState,
  setShowCompareModal,
  setIsComparingToml,
}: Readonly<{
  activeTabState: [string, (value: string) => void];
  setShowCompareModal: (showCompareModal: boolean) => void;
  setIsComparingToml: (isComparingToml: boolean) => void;
}>) {
  const [activeTab] = activeTabState;
  const modal = useRef<HTMLDivElement>(null);
  const [tomlString, setTomlString] = useState<string | null>(null);
  const { downloadToml, isDownloadingToml } = useTomlExport(
    "unique new contacts list",
    activeTab,
    tomlString
  );
  const [fileInput, setFileInput] = useState<File | undefined>(undefined);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target?.files?.[0];
    setFileInput(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target?.result as string;
        if (!content) return;
        try {
          const parsedToml = toml.parse(content);
          const tomlStr = toml.stringify(parsedToml);
          setTomlString(tomlStr);
        } catch (error) {
          console.error("Error parsing TOML file:", error);
        }
      };
      reader.readAsText(file);
    }
  };

  const compareToml = () => {
    if (!tomlString) return;
    setIsComparingToml(true);
    downloadToml()
      .then(() => {
        setShowCompareModal(false);
        setIsComparingToml(false);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    // Courtesy of https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
    function handleClickOutside(event: MouseEvent) {
      if (modal.current && !modal?.current.contains(event.target as Node)) {
        setShowCompareModal(false);
        setIsComparingToml(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modal, setShowCompareModal]);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg" ref={modal}>
        <p className="text-lg font-semibold mb-4">
          Import the TOML file you want to compare with your OpenQ list.
        </p>
        <input
          type="file"
          onChange={handleFileChange}
          className="hidden"
          id="fileInput"
        />
        <div className="flex gap-4 items-center mb-4">
          <Button
            className={`${
              fileInput?.name
                ? "border-none bg-vlight text-center text-offblack hover:bg-vlight"
                : ""
            }`}
          >
            <label htmlFor="fileInput" className="cursor-pointer">
              {fileInput?.name ? "Change file" : "Choose file"}
            </label>
          </Button>

          <p>{fileInput?.name ?? "No file selected"}</p>
        </div>
        <div className="flex justify-end gap-4">
          <Button onClick={() => setShowCompareModal(false)}>Cancel</Button>
          <Button
            className="bg-blue-500 hover:bg-blue-600"
            onClick={compareToml}
            disabled={isDownloadingToml || !tomlString}
          >
            Compare
            <LoadingSpinner show={isDownloadingToml} className="ml-2 sm-icon" />
          </Button>
        </div>
      </div>
    </div>
  );
}
